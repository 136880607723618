footer {
    color: #aaa;
    font-size: 12px;
    text-align: right;
    padding: 5px;
    margin-top: 5px;
}

footer a {
    color: #aaa;
}

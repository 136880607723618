.basic-info-container {
    padding: 1px;
    margin-top: 6px;
}

.utilities-item.basic-info-container > h2 {
    margin-top: 5px;
}

.basic-info-fixh {
    height: 92px;
}

.basic-info-container .basic-info-stats {
    padding-top: 8px;
}

.basic-info-container.conflicts .basic-info-stats {
    margin-top: 0;
}

.basic-info-conflict-container {
    text-align: center;
}

.basic-info-conflict {
    display: inline-block;
    border: 1px solid #ff8d8d;
    border-radius: 4px;
    margin: 0 5px 0 10px;
    padding: 3px 6px;
    color: #ff8d8d;
    cursor: pointer;
    font-size: 12px;
    font-weight: normal;
}

.basic-info-conflict.showing {
    background-color: #ffefef;
}

.basic-info-conflict > p {
    margin: 0;
}

.basic-info-stats {
    font-size: 36px;
    font-weight: 400;

    @media (max-width: #{$small-screen-cutoff}) {
        font-size: 24px;
    }
}

.basic-info {
    display: inline-block;
    width: 80px;
    margin: 5px;
    text-align: center;

    @media (max-width: #{$small-screen-cutoff}) {
        width: 60px;
        margin: 2px;
    }
}

.basic-info:first-child {
    margin-left: 20px;
    @media (max-width: #{$small-screen-cutoff}) {
        margin-left: 5px;
    }
}

.basic-info-container.conflicts .basic-info {
    font-size: 24px;
}

.basic-info-title {
    font-size: 12px;
    margin: 3px;
}

.basic-info-value {
    margin: 3px;
}

.total-credit-range .basic-info-value {
    font-size: 0.7em;
}

//-------------------------------------------
// Week Selector
//-------------------------------------------
.week-selector {
    display: flex;
    flex-direction: row;
    align-items: center;

    margin: 8px 5px 8px 10px;
}

.week-selector-btn {
    border: 1px solid #979797;
    border-radius: 4px;
    font-size: 12px;
    height: 20px;
    color: #6C6C6C;
    @include no-user-interaction;
}

.week-selector-btn.unselected {
    opacity: 0.4;
    cursor: pointer;
}

.all-btn {
    font-weight: 500;
    padding: 0 7px;
    margin-right: 5px;
    line-height: 20px;
}

.week-btn {
    flex-grow: 1;

    display: flex;
    align-items: center;
}

.week-btn .current-week {
    flex-grow: 1;
    text-align: center;
}

.prev-week, .next-week {
    font-size: 8px;
    padding: 4px 5px;
    vertical-align: middle;
    color: #979797;
}

.prev-week.disabled, .next-week.disabled {
    color: #E3E3E3;
}

.prev-week.enabled, .next-week.enabled {
    cursor: pointer;
}

@media (min-width: #{$small-screen-cutoff}) {
    .dateinterval-short {
        display: none;
    }
}
@media (max-width: #{$small-screen-cutoff}) {
    .dateinterval-long {
        display: none;
    }
}


.utilities-item > h2 {
    text-transform: uppercase;
    font-size: 16px;
    color: #666;
    margin: 18px 10px 10px 10px;
}

.rightbar-simple-ul.utilities-item ul {
    list-style: none;
    font-size: 14px;
    padding: 0;
    margin: 5px 5px 5px 20px;
}

.rightbar-simple-ul.utilities-item a {
    color: black;
    text-decoration: none;
}

.rightbar-simple-ul.utilities-item a:hover {
    text-decoration: underline;
}

.basket-item {
    padding: 5px;
    margin: 10px;
}

.basket-item.invisible {
    color: #aaa;
}

.basket-item .content-buttons {
    float: right;
    margin-left: 5px;
    @include no-user-interaction;
}

@media (max-width: #{$small-screen-cutoff}) {
    .basket-item .content-buttons {
        display: none;
    }

    .basket-item:hover .content-buttons {
        display: block;
    }
}

.basket-item .content {
}

.basket-item .content-title {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 0px;
}

.basket-item .content-buttons .btn {
    cursor: pointer;
    display: inline-block;
    width: 18px;
    height: 18px;
    margin: 0 1px;
}

.basket-item .content-buttons .menu-btn {
    @include centered-img-with-size('basket_menu', 16px, 16px);
    margin-right: 3px;
}

.basket-item .content-buttons .visibility-btn {
    @include centered-img-with-size('basket_eye_open', 16px, 16px);
}

.basket-item .content-buttons .visibility-btn.closed {
    @include centered-img-with-size('basket_eye_closed', 16px, 16px);
}

.basket-item .content-buttons .expand-btn {
    @include centered-img-with-size('basket_tri_closed', 16px, 16px);
}

.basket-item .content-buttons .expand-btn.expanded {
    @include centered-img-with-size('basket_tri_open', 16px, 16px);
}

.basket-item .content.level-2 {
    margin: 5px 0 5px 12px;
}

.selected-indicator {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 3px;
    margin-left: -10px;
    background-color: gray;
}
.selected-indicator.selectable {
    cursor: pointer;
}

.selected-indicator.selected {
    background-color: green;
}

@each $name, $color in $palette {
    .basket-item.#{$name} {
        border-left: 3px solid $color;
    }
}

/*--------------------------------------------*/
/* Magic */
/*--------------------------------------------*/
.magic.utilities-item > .utilities-content {
    margin-left: 20px;
}

.magic.utilities-item .description {
    margin: 4px 0;
    font-size: 14px;
}

.magic.utilities-item ul {
    list-style: none;
    font-size: 14px;
    padding-left: 10px;
    margin: 5px 0;
}

.magic.utilities-item ul label {
    display: inline-block;
    width: 120px;
}

.magic.utilities-item > .utilities-content > .btn {
    background-color: #fafafa;
    display: inline-block;
    border: 1px solid #aaa;
    padding: 5px;
    border-radius: 3px;
}

.magic.utilities-item .hint {
    font-size: 12px;
    margin-top: 3px;
}

.magic.utilities-item p {
    font-size: 14px;
    margin: 0;
}

.magic.utilities-item p.side {
    font-size: 12px;
}

/*-------------------------------------------*/
.sharing-social-networks {
    display: flex;
}

.sharing-inner {
    margin-left: 20px;
    font-size: 14px;
}

.sharing-inner input {
    font-size: 12px;
}

.sharing-inner p {
    margin: 5px 0;
}
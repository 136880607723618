$small-header-cutoff: 1000px;
$compact-header-cutoff: 650px;

header {
    margin: 0 0 0 $leftbar-width-collapsed;
    padding: 0;
    height: $topbar-height;
    color: white;

    display: flex;
    align-items: center;
}

#logo {
    width: 170px;
    height: $topbar-height;
    margin: 0 0 0 10px;
    background-image: url('../img/logo.png');

    background-repeat: no-repeat;
    background-position: center left;
    background-size: 170px 24px;
    @media print,
       (-o-min-device-pixel-ratio: 5/4),
       (-webkit-min-device-pixel-ratio: 1.25),
       (min-resolution: 120dpi) {
        background-image: url('../img/logo@2x.png');
    }

    float: left;
    order: 1;


    @media (max-width: #{$compact-header-cutoff}) {
        width: 30px;
    }
}

#topnav {
    float: right;
    margin-right: 15px;
    display: flex;
    align-items: center;
    order: 3;
}

/*#topnav div {
    line-height: $topbar-height;
}*/

/* Search */
#topsearch {
    order: 2;
    flex-grow: 1;
    margin-left: 50px;

    @media (max-width: #{$small-header-cutoff}) {
        margin-left: 15px;
    }
}

#topsearch input {
    width: calc(100% - 50px);
    @media (max-width: #{$small-header-cutoff}) {
        width: calc(100% - 30px);
    }
    max-width: 450px;

    border: 1px solid black;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-weight: 300;
    padding: 5px;
}

.ui-autocomplete {
    position: absolute;
    list-style: none;
    background-color: white;
    padding: 5px 0 5px 0;
    z-index: 10;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

    max-height: 500px;
    overflow-x: hidden;
    overflow-y: auto;
}

.ui-autocomplete .ui-menu-item {
    padding: 5px;
}

.ui-autocomplete .ui-menu-item.ui-state-focus {
    background-color: #eee;
}

.ui-autocomplete .ui-menu-item p {
    font-size: 14px;
    margin: 0;
    text-overflow: ellipsis;
}

.ui-autocomplete .ui-menu-item p em {
    font-style: normal;
    font-weight: 500;
    text-decoration: underline;
}

/* -------------------------------- */
/* User */

#current-user {
    margin-left: 16px;
}

.current-user-inner > span {
    display: flex;
    align-items: center;

    white-space: nowrap;
    text-overflow: ellipsis;
}

.topbar-user-icon {
    box-shadow: 0px 0px 5px 0px rgba(255,255,255,0.85);
    background-image: url('../img/mystery-man.jpg');
    background-size: cover;
    width: 24px;
    height: 24px;
    border-radius: 12px;
    margin-right: 8px;
}

.user-menu {
    background-color: white;
    border: 1px solid #CBCBCB;
    margin: 5px;
    width: 180px;
}

.user-menu .user-avatar {
    background-image: url('../img/mystery-man.jpg');
    background-size: cover;
    width: 32px;
    height: 32px;
    border: 2px solid #ccc;
    border-radius: 18px;
    margin: 5px auto;
}

.user-options {
    padding-bottom: 6px;
}

.user-options p {
    margin: 2px 4px;
}

.user-options hr {
    border: 0;
    height: 1px;
    background-color: #CBCBCB;
}

.user-info {
    text-align: center;
    margin: 12px 0;
}

.user-info .user-name {
    font-weight: normal;
}

.user-menu.guest {
    white-space: nowrap;
    overflow: hidden;
}

.user-menu.guest > div {
    width: 100%;
    display: inline-block;
    vertical-align: top;
}

.user-loginform-inner {
    padding: 5px;

    label {
        display: block;
    }
}

.user-menu.guest > div:first-child {
    transition: margin 0.5s;
}

.user-menu.two > div:first-child {
    margin-left: -100%;
}

@media (max-width: #{$compact-header-cutoff}) {
    .current-user-name {
        display: none;
    }
}

/* Term Selector */
#term-selector {
    white-space: nowrap;
    text-overflow: ellipsis;
}
/* ------------------------------------------- */
/* Schedules                                   */
/* ------------------------------------------- */
$calendar-element-width: 120px;
$calendar-element-gutter: 20px;

#main-container {
    position: relative;
    overflow-x: hidden;
}



#main {
    width: calc(100% - #{$leftbar-width-collapsed});
    margin-left: $leftbar-width-collapsed;
    transition: margin 0.3s;
    background-color: white;
}


$cal-time-width: 70px;

#calendar {
    float: left;
    width: calc(100% - #{$right-column-width});
    @media (max-width: #{$small-screen-cutoff}) {
        width: calc(100% - #{$right-column-width-small});
    }
}

#utilities {
    width: $right-column-width;
    float: right;

    @media (max-width: #{$small-screen-cutoff}) {
        width: $right-column-width-small;
    }
}

.cal-inner {
    position: relative;
    overflow-x: hidden;
}

.cal-meetings {
    margin-left: $cal-time-width;
    padding-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

.cal-meetings-inner {
    margin: 0 auto 0 auto;
    position: relative;
    width: 4 * $calendar-element-gutter + 5 * $calendar-element-width;
}

.cal-meetings-inner-fullweek {
    margin: 0 auto 0 auto;
    position: relative;
    width: 6 * $calendar-element-gutter + 7 * $calendar-element-width;
}

.cal-stripe {
    height: 62px;
    padding-top: 3px;
}

.cal-time {
    color: #7E7E7E;
    font-size: 18px;
    text-align: right;
    width: $cal-time-width;
}

.cal-stripes {
    @include no-user-interaction;
}

.cal-stripe.odd {
    background-color: #FAFAFA;
}

.calele {
    width: $calendar-element-width;
    position: absolute;
    z-index: 1;
}

.calele-m {
    left: 0;
}

.calele-t {
    left: $calendar-element-gutter + $calendar-element-width;
}

.calele-w {
    left: 2 * $calendar-element-gutter + 2 * $calendar-element-width;
}

.calele-r {
    left: 3 * $calendar-element-gutter + 3 * $calendar-element-width;
}

.calele-f {
    left: 4 * $calendar-element-gutter + 4 * $calendar-element-width;
}

.calele-s {
    left: 5 * $calendar-element-gutter + 5 * $calendar-element-width;
}

.calele-u {
    left: 6 * $calendar-element-gutter + 6 * $calendar-element-width;
}

.meeting {
}

.meeting p {
    margin: 0;
    text-align: center;
}

p.meeting-title {
    font-size: 14px;
    margin: 3px 0 3px 0;
}

p.meeting-time, p.meeting-loc {
    font-size: 10px;
}



@each $name, $color in $palette {
    .meeting.#{$name} {
        background-color: $color;
    }
}

/**
 * z-index table
 * 0 : stripe;
 * 1 : normal elements;
 * 2 : mutable elements;
 * 3 : conflict overlay;
 * 4 : dropoff;
 * 5 : dragging elements;
 */

.meeting.mutable {
    @include no-user-interaction;
    z-index: 2;
}


.meeting.mutable:hover, .meeting.mutable *:hover, {
    cursor: -moz-grab;
    cursor: -webkit-grab;
    cursor: grab;
}

.meeting.mutable:hover {
    border: 2px solid black;
    margin: -2px;
}

.meeting.mutable:active,
.meeting.mutable:active *,
.meeting.mutable.ui-draggable-dragging:hover,
.meeting.mutable.ui-draggable-dragging:hover * {
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
    cursor: grabbing;
    z-index: 5;
}

.mtdropoff {
    border: 2px dashed #AEAEAE;
    background-color: rgba(250, 250, 250, 0.95);
    margin: -2px;


    z-index: 4;
    color: #949494;
    text-align: center;
}

.mtdropoff-highlight {
    background-color: rgba(225, 225, 225, 0.95);
}

.mtdropoff-inner {
    display: inline-block;
  vertical-align: middle;
  width: 100%;
}

#conflict-overlay {
    visibility: hidden;
    transition: visibility 0s;

    transition-delay: 0.3s;
}

#conflict-overlay.show {
    visibility: visible;

    transition-delay: 0s;
}

#conflict-overlay > div {
    transition: opacity 0.3s;
    opacity: 0;
}

#conflict-overlay.show > div {
    opacity: 1;
}

.conflict-indicator {
    z-index: 3;
    background-color: rgba(255, 144, 144, 0.7);

}

.mtdropoff:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mtdropoff p {
    font-size: 10px;
    margin: 2px;
}
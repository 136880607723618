body.modal-open {
    overflow: hidden;
}

.modal-container {
    position: fixed;
    overflow: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 499;
}

.modal-backdrop {
    background-color: rgba(0,0,0,0.75);
    position: fixed;
    overflow-y: auto;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

$modal-width: 700px;

.modal-window {
    position: relative;
    width: $modal-width;
    background-color: white;
    margin: 60px auto;
    @media (max-width: #{$modal-width}) {
        width: auto;
        margin: 10px;
    }
}

.modal-title {
    height: 20px;
    clear: both;
}

span.modal-close {
    display: block;
    float: right;
}


.modal-alert > h2 {
  font-weight: normal;
  font-size: 16px;
  padding: 10px;
}

.modal-alert .btn-row {
    text-align: center;
    padding: 0;
}

.modal-alert .btn-row .btn {
    margin: 5px 3px;
    padding: 5px;
    border: 1px solid #aaa;
    border-radius: 4px;
    background-color: #eee;
    min-width: 50px;
}

.modal-alert-content {
    padding: 3px 10px;
}
